import React from 'react'
import './Team.css'
import yolanda from '../Assets/images/yolanda.JPG'
import niquee from '../Assets/images/niquee.JPG'
import ernesta from '../Assets/images/ernesta.JPG'

export default function Team() {
  return (
    <div id='team'>
      <div id='team-container'>
        <h2 style={{color: '#9D7A68'}}>the team</h2>
        <p style={{width: 800, maxWidth: '100%'}}>Each member of our team brings skill, creativity and a genuine dedication to understanding and enhancing your unique beauty. At art.salon, we’re honoured to be a part of your personal style journey.</p>
          
        <div style={{display:'flex', justifyContent:'space-between', margin: '50px 0', flexWrap:'wrap'}}>
          <div className='staff-img'>
            <img style={{width: '100%'}} src={yolanda}/>
            <p style={{fontFamily: 'Kamerik, sans-serif', fontSize: 22}}>yolanda</p>
            <p>Senior Stylist</p>
          </div>
          <div className='staff-img'>
            <img style={{width: '100%'}} src={niquee}/>
            <p style={{fontFamily: 'Kamerik, sans-serif', fontSize: 22}}>niquee</p>
            <p>Senior Stylist</p>
          </div>
      
          <div className='staff-img'>
            <img style={{width: '100%'}} src={ernesta}/>
            <p style={{fontFamily: 'Kamerik, sans-serif', fontSize: 22}}>ernesta</p>
            <p>Senior Nail Technician</p>
          </div>
        </div>
      </div>
      
    </div>
  )
}
