import React from 'react'
import './Hero.css'

export default function Hero({ setOpen }) {

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div id='hero'>
      <div id='overlay'/>

      <div id='hero-info'>
        <h3 style={{color: 'white', width: 600, maxWidth: '100%'}}>experience the art of self care</h3>
        <p style={{color: 'white', width: 550, maxWidth: '100%', margin: '30px 0 50px 0', lineHeight: 1.5, fontSize: 16}}>Our salon isn’t just about looking good—it’s about feeling amazing, too. Every service is crafted to empower, uplift, and help you feel your best, all in a setting that’s as comfortable as it is luxurious.</p>
        <div>
          <button className='white-solid' style={{marginRight: 20}} onClick={ () => setOpen(true)}>Book Now</button>
          <button className='white-btn' onClick={() => handleClickScroll('about')}>Learn More</button>
        </div>
      </div>
    </div>
  )
}
