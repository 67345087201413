import React, { useState, useEffect, useRef } from 'react';
import './Salon.css'
import img1 from '../Assets/images/gallery1.jpg'
import img2 from '../Assets/images/gallery2.jpeg'
import img3 from '../Assets/images/gallery3.jpg'
import img4 from '../Assets/images/gallery4.jpeg'
import img5 from '../Assets/images/gallery5.jpg'
import img6 from '../Assets/images/gallery6.jpg'
import img7 from '../Assets/images/gallery7.jpg'
import img8 from '../Assets/images/gallery8.jpg'

export default function Salon() {
  const [scrollPercentage, setScrollPercentage] = useState(12.5);
  const galleryRef = useRef(null);
  const minScrollWidth = 12.5; // Set the minimum width for the scrollbar to 12.5%
 
 
  useEffect(() => {
    const handleScroll = () => {
      if (galleryRef.current) {
        const scrollLeft = galleryRef.current.scrollLeft;
        const scrollWidth = galleryRef.current.scrollWidth - galleryRef.current.clientWidth;
        const scrolled = (scrollLeft / scrollWidth) * 100;

        // Ensure the width is at least 12.5% when scrolled to the beginning
        const width = Math.max(scrolled, minScrollWidth);

        setScrollPercentage(width);
      }
    };

    const gallery = galleryRef.current;
    if (gallery) {
      gallery.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (gallery) {
        gallery.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div id='salon'>
      <div id='salon-container'>
        <h2 style={{color: '#9D7A68'}}>the salon</h2>
        <p style={{margin: '20px 0 30px 0', width: 1400, maxWidth: '100%'}}>Step into a beautifully curated salon where style meets comfort, and luxury is woven into every detail. Our space combines artistry and elegance, offering a sanctuary designed for your relaxation and beauty.</p>
        <div id='gallery' ref={galleryRef}>
          <img src={img1} className='gallery-img'/>
          <img src={img2} className='gallery-img'/>
          <img src={img3} className='gallery-img'/>
          <img src={img4} className='gallery-img'/>
          <img src={img5} className='gallery-img'/>
          <img src={img6} className='gallery-img'/>
          <img src={img7} className='gallery-img'/>
          <img src={img8} className='gallery-img'/>
        </div>

        <div id='scroll-container'>
          <div 
            id='scroll-percentage'             
            style={{ width: `${scrollPercentage}%` }}
          />
        </div>
      </div>
    </div>
  )
}
