import React from 'react'
import './About.css'
import about from '../Assets/images/about.webp'

export default function About({ setOpen }) {
  return (
    <div id='about'>
        <div id='about-container'>
            <img id='about-img' src={about} className='mobile-remove'/>
            <div id='about-info'>
                <h2 style={{color: '#9D7A68'}}>about</h2>
                <p style={{margin: '20px 0 30px 0'}}>As Birmingham’s first hijab-friendly, women-only salon, we celebrate beauty and individuality in every strand. From timeless cuts to modern styles, we approach every service with artistry, precision, and a personal touch that’s designed to suit you.</p>
                <button className='black-btn' onClick={ () => setOpen(true)}>Book Now</button>
            </div>

        </div>
        <img id='about-img' src={about} className='mobile' style={{width: '100%'}}/>

    </div>
  )
}
