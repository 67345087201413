import React, { useState } from 'react'
import './Contact.css'
import contact from '../Assets/images/contact.webp'
import axios from 'axios'
import { toast } from 'react-toastify'
import phone from '../Assets/images/phone.png'

export default function Contact() {

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [msg, setMsg] = useState("")
  const [sending, setSending] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    let payload = {
      name, email, number, msg
    }

    if(!name || !email || !number || !msg){
      return toast.error("Please add all fields")
    }
    setSending(true)
    axios.post(`${process.env.REACT_APP_API}/artdotsalon/contact`, payload)
    .then(() => {
      handleClear()
      toast.success("Successfully sent")
    })
    .catch(() => {
      toast.error("Error sending")
    })
    .finally(() => {
      setSending(false)
    })
  }

  const handleClear = () => {
    setName("")
    setEmail("")
    setNumber("")
    setMsg("")
  }

  return (
    <div id='contact'>
        <h2 style={{opacity: 0.7}}>contact</h2>

        <div className='mobile-remove'>
          <p style={{textAlign: 'center', maxWidth: 650}}>Fill out the form below, and our team will be in touch to confirm your booking and answer any questions. We look forward to welcoming you to our salon!</p>
          <form style={{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
            <input placeholder='name' value={name} onChange={(e) => setName(e.target.value)}/>
            <input placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
            <input placeholder='phone number' value={number} onChange={(e) => setNumber(e.target.value)}/>
            <textarea placeholder='message' value={msg} onChange={(e) => setMsg(e.target.value)}/>
            <button onClick={handleSubmit} className='black-btn' style={{width: 100, margin: '20px auto'}} disabled={sending}>Send</button>
          </form>

          <p style={{textAlign: 'center'}}>Alternatively you can <a style={{fontWeight: 'bold', color: '#313131'}} href='tel: 07398 588030'>Call us to book</a></p>
        </div>

        <img src={contact} id='contact-img' />

        <div className='mobile' style={{width: '90%', margin: '20px auto'}}>
          <p style={{textAlign: 'center', maxWidth: 650}}>Fill out the form below, and our team will be in touch to confirm your booking and answer any questions. We look forward to welcoming you to our salon!</p>
          <form style={{display: 'flex', flexDirection:'column'}}>
            <input placeholder='name' value={name} onChange={(e) => setName(e.target.value)}/>
            <input placeholder='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
            <input placeholder='phone number' value={number} onChange={(e) => setNumber(e.target.value)}/>
            <textarea placeholder='message' value={msg} onChange={(e) => setMsg(e.target.value)}/>
            <button onClick={handleSubmit} className='black-btn' style={{width: 100, marginTop: 20}} disabled={sending}>Send</button>
          </form>
          <p >Alternatively you can <a style={{fontWeight: 'bold', color: '#313131'}} href='tel: 07398 588030'>Call us to book</a></p>

        </div>

    </div>
  )
}
