import React from 'react'
import './Footer.css'
import logo from '../Assets/images/artdotsalon.png'
//socials
import phone from '../Assets/images/phone.png'
import insta from '../Assets/images/insta.png'
import fb from '../Assets/images/fb.png'
import tiktok from '../Assets/images/tiktok.png'

export default function Footer() {
  return (
    <footer>
      <div>
        <img src={logo} style={{height: 50, marginBottom: 20}}/>
        <p className='address-line'>Art Salon</p>
        <p className='address-line'>12 Allison St</p>
        <p className='address-line'>Birmingham</p>
        <p className='address-line'>B5 5TH</p>
      </div>

      <div id='footer-right'>
        <div style={{marginBottom: 30}}>
          <a style={{color: 'white', textDecoration: 'none'}} href='tel: 07398 588030'>
            <img className='social' src={phone}/>
          </a>
          <a href='https://instagram.com/artdotsalon' target='_blank'>
            <img className='social' src={insta}/>
          </a>
          <a href='https://facebook.com/artdotsalon' target='_blank'>
           <img className='social' src={fb}/>
          </a>
          <a href='https://www.tiktok.com/@artdotsalon' target='_blank'>
            <img className='social' src={tiktok}/>
          </a>
        </div>

        <p>Operating Hours</p>
        <p>10am - 8pm</p>
        <p>Contact number</p>
        <a >07398 588030</a>

        <a style={{marginTop: 30, textDecoration: 'none', color: 'white'}} href='https:frantech.org.uk' target='_blank'>Website by Frantech</a>
      </div>
        
    </footer>
  )
}
