import React, { useState } from 'react';
import './Services.css';
import arrow from '../Assets/images/arrow.png';
import { stylists } from '../Constants';

export default function StylistDropdown() {
  const [openStylist, setOpenStylist] = useState(null);

  const toggleAccordion = (stylist) => {
    setOpenStylist(openStylist === stylist ? null : stylist);
  };

  return (
    <div className="accordion">
      {Object.keys(stylists).map((stylistName) => (
        <div key={stylistName}>
          {/* Accordion Header */}
          <div
            className="accordion-header"
            onClick={() => toggleAccordion(stylistName)}
            style={{ padding: '30px 0' }}
          >
            {stylistName === 'braids_locs' ? 'braids & locs' : stylistName}
            <img
              src={arrow}
              className={openStylist === stylistName ? 'arrow-open' : 'arrow-closed'}
              alt="Toggle Arrow"
            />
          </div>

          {/* Accordion Body */}
          <div
            className={`accordion-body ${openStylist === stylistName ? 'open' : 'collapsed'}`}
          >
            <div className="accordion-content">
              {stylists[stylistName].map((serviceCategory, idx) => (
                <div key={idx} style={{ marginBottom: '15px' }}>
                  {/* Service Header */}
                  <h4 style={{ fontWeight: 'bold' }}>{serviceCategory.service}</h4>
                  {/* Sub Services */}
                  <ul style={{ paddingLeft: '20px' }}>
                    {(serviceCategory.subService || serviceCategory.subServices || []).map((sub, subIdx) => (
                      <li
                        key={subIdx}
                        style={{
                          listStyleType: 'none',
                          margin: '5px 0',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span>{sub.name}</span>
                        <span>
                          £
                          {sub.minPrice === sub.maxPrice
                            ? sub.minPrice
                            : `${sub.minPrice} - £${sub.maxPrice}`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
